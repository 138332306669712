<script>
export default {
    'name': 'FeaturesRedirect',
    created() {
        const {params, query} = this.$route,
            {path} = params;

        this.$router.replace({'path': '/' + path, query});
    },
    'render': function (h) {
        return h();
    }
};
</script>
